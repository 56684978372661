<template>
  <b-container
    fluid
  >
    <div class="d-flex justify-content-end">
      <b-breadcrumb
        v-if="$route.meta.breadcrumb"
        :items="$route.meta.breadcrumb || []"
      />
    </div>

    <b-container
      fluid
    >

      <b-row
        class="border-bottom"
      >
        <b-col
          cols="12"
          md="6"
          offset-md="6"
        >
          <ValidationObserver
            ref="formSynchronizer"
            v-slot="{ errors: formErrors }"
          >
            <b-row>

              <b-col
                cols="6"
                md="4"
                class="px-1"
              >
                <ValidationProvider
                  #default="{ errors }"
                  vid="start_date"
                  name="start date"
                  rules="required"
                >
                  <b-form-group
                    label="Start Date"
                    label-for="synchronizeStartDate"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="synchronizeStartDate"
                      v-model="synchronize.start_date"
                      :state="(errors.length > 0 ? false : null)"
                      :disabled="state.synchronizing"
                      :max="synchronize.end_date"
                      debounce="1000"
                      type="date"
                    />
                    <div
                      v-if="(errors.length > 0)"
                      class="invalid-feedback"
                      v-text="errors[0]"
                    />
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col
                cols="6"
                md="4"
                class="px-1"
              >
                <ValidationProvider
                  #default="{ errors }"
                  vid="end_date"
                  name="end date"
                  rules="required"
                >

                  <b-form-group
                    label="End Date"
                    label-for="synchronizeEndDate"
                    label-class="font-weight-bolder"
                    class="mb-2"
                  >
                    <b-input
                      id="synchronizeEndDate"
                      v-model="synchronize.end_date"
                      :state="(errors.length > 0 ? false : null)"
                      :disabled="state.synchronizing"
                      :min="synchronize.start_date"
                      debounce="1000"
                      type="date"
                    />
                    <div
                      v-if="(errors.length > 0)"
                      class="invalid-feedback"
                      v-text="errors[0]"
                    />
                  </b-form-group>
                </ValidationProvider>
              </b-col>

              <b-col
                cols="6"
                md="4"
                class="px-1 pb-2 d-flex align-items-end"
              >
                <b-container
                  fluid
                >
                  <b-row>
                    <b-col
                      cols="6"
                      class="pr-1 pl-0"
                    >
                      <b-overlay
                        :show="state.synchronizing"
                        rounded="sm"
                        opacity="0.6"
                        spinner-small
                        spinner-variant="success"
                        class="p-0"
                      >
                        <b-button
                          :variant="`${state.synchronizing ? 'outline-dark' : 'success'}`"
                          :disabled="(state.importing || state.synchronizing)"
                          block
                          type="button"
                          @click.prevent="synchronizeCategories"
                        >
                          Sync
                        </b-button>
                      </b-overlay>
                    </b-col>
                    <b-col
                      cols="6"
                      class="px-0"
                    >
                      <b-overlay
                        :show="state.importing"
                        rounded="sm"
                        opacity="0.6"
                        spinner-small
                        spinner-variant="warning"
                        :class="[
                          'p-0', {
                            'mb-3 pb-1': (
                              Object.keys(formErrors).filter(
                                error => formErrors[error]?.length > 0
                              ).length > 0
                            )
                          }
                        ]"
                      >
                        <b-button
                          :variant="`${state.importing ? 'outline-dark' : 'warning'}`"
                          :disabled="(state.importing || state.synchronizing)"
                          block
                          type="button"
                          @click.prevent="importCategories"
                        >
                          Import
                        </b-button>
                      </b-overlay>
                    </b-col>
                  </b-row>
                </b-container>
              </b-col>
            </b-row>
          </ValidationObserver>
        </b-col>

      </b-row>

      <b-row
        class="mt-2"
      >
        <b-col
          cols="12"
          md="9"
          class="px-1"
        >
          <b-form-group
            label="Search"
            label-for="filterCategorySearch"
            label-class="font-weight-bolder"
          >
            <b-input
              id="filterCategorySearch"
              v-model="tableCategories.filter.search"
              placeholder="search here"
              autocomplete="off"
              debounce="1000"
              type="text"
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="12"
          md="3"
          class="px-1"
        >
          <b-form-group
            label="Group"
            label-for="filterGroup"
            label-class="font-weight-bolder"
            class="mb-2"
          >
            <v-select
              v-model="tableCategories.selected.group"
              placeholder="search group here"
              class="vs-white"
              label="group_name"
              clearable
              append-to-body
              input-id="filterGroup"
              :options="(tableCategories.options.groups)"
              :loading="(tableCategories.fetching.groups)"
              :disabled="(tableCategories.busy || tableCategories.fetching.groups)"
              :calculate-position="calculateDropPosition"
            >
              <template #option="{ group_name }">
                <div class="py-1">
                  <div>{{ group_name }}</div>
                </div>
              </template>
              <template #no-options="">
                no available group
              </template>
            </v-select>
          </b-form-group>
        </b-col>

      </b-row>

      <b-row
        class="mt-1"
      >
        <b-col
          cols="12"
          class="px-1"
        >
          <b-table
            ref="tableCategories"
            hover
            small
            striped
            bordered
            responsive
            show-empty
            :items="tableProvider"
            :busy="tableCategories.busy"
            :filter="tableCategories.filter"
            :fields="tableCategories.fields"
            :stacked="tableCategories.stacked"
            :sort-by.sync="tableCategories.sortBy"
            :sort-desc.sync="tableCategories.sortDesc"
            :sort-direction="tableCategories.sortDirection"
            :filter-included-fields="tableCategories.filterOn"
            :current-page="tableCategories.currentPage"
            :per-page="tableCategories.perPage"
          >
            <template #cell(index)="{ index }">
              {{ tableRowNumber(tableCategories, index) }}
            </template>

            <template #cell()="{ value }">
              <div class="text-md-nowrap">
                {{ value }}
              </div>
            </template>

            <template #cell(action)="{ item }">
              <div class="text-md-nowrap d-flex flex-column flex-md-row justify-content-center">
                <b-button
                  size="sm"
                  variant="success"
                  @click.prevent="editCategory(item)"
                >
                  Edit
                </b-button>
              </div>
            </template>

            <template #table-busy>
              <div class="text-center py-5">
                <b-icon
                  icon="stopwatch"
                  font-scale="5"
                  animation="cylon"
                />
                <p class="h3 py-2">
                  <strong>Loading . . .</strong>
                </p>
              </div>
            </template>

          </b-table>
        </b-col>

        <b-col
          cols="12"
          md="6"
          class="mt-1 mb-2 px-1"
        >
          <b-select
            v-model="tableCategories.perPage"
            :options="tableCategories.pageOptions"
            :disabled="tableCategories.busy"
            class="w-100 w-md-25"
            size="sm"
          />
        </b-col>

        <b-col
          cols="12"
          md="6"
          class="d-flex justify-content-center justify-content-md-end mt-1 px-1"
        >
          <b-pagination
            v-model="tableCategories.currentPage"
            :total-rows="tableCategories.totalRows"
            :per-page="tableCategories.perPage"
            :disabled="tableCategories.busy"
            aria-controls="table"
            prev-text="Prev"
            next-text="Next"
            first-number
            last-number
            pills
          />
        </b-col>
      </b-row>

    </b-container>

    <b-modal
      id="modalCategory"
      scrollable
      no-close-on-esc
      no-enforce-focus
      no-close-on-backdrop
      title="Edit Category"
    >
      <ValidationObserver
        ref="formCategory"
      >
        <form
          role="form"
          novalidate
          @submit.prevent
        >
          <b-row>
            <b-col
              cols="12"
            >
              <b-form-group
                label="Category Code"
                label-for="category_code"
                label-class="font-weight-bolder"
                class="mb-2"
              >
                <b-input
                  id="category_code"
                  v-model="category.category_code"
                  type="text"
                  maxlength="30"
                  autocomplete="off"
                  placeholder="enter category code here"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group
                label="Category Name"
                label-for="category_name"
                label-class="font-weight-bolder"
                class="mb-2"
              >
                <b-input
                  id="category_name"
                  v-model="category.category_name"
                  type="text"
                  maxlength="100"
                  autocomplete="off"
                  placeholder="enter category name here"
                  disabled
                />
              </b-form-group>
            </b-col>

            <b-col
              cols="12"
            >
              <ValidationProvider
                #default="{ errors }"
                vid="group"
                name="group"
              >
                <b-form-group
                  label="Group"
                  label-for="group"
                  label-class="font-weight-bolder"
                  class="mb-2"
                >
                  <v-select
                    v-model="selected.group"
                    placeholder="search group here"
                    label="group_name"
                    clearable
                    append-to-body
                    input-id="group"
                    :options="(options.groups)"
                    :loading="(state.busy || fetching.groups)"
                    :disabled="(fetching.groups)"
                    :calculate-position="calculateDropPosition"
                    :class="{ 'is-invalid': errors.length > 0 }"
                  >
                    <template #option="{ group_name }">
                      <div class="py-1">
                        <div>{{ group_name }}</div>
                      </div>
                    </template>
                    <template #no-options="">
                      no available group
                    </template>
                  </v-select>
                  <div
                    v-if="(errors.length > 0)"
                    class="invalid-feedback"
                    v-text="errors[0]"
                  />
                </b-form-group>
              </ValidationProvider>
            </b-col>

            <b-col
              cols="12"
            >
              <b-form-group>
                <b-form-checkbox
                  id="is_active"
                  v-model="category.active"
                  switch
                  value="1"
                  name="is_active"
                  unchecked-value="0"
                  :disabled="state.busy"
                >
                  Is Active?
                </b-form-checkbox>
              </b-form-group>
            </b-col>
          </b-row>

        </form>
      </ValidationObserver>

      <template #modal-footer="{cancel}">
        <b-button
          variant="success"
          :disabled="state.busy"
          @click.prevent="validateCategory"
        >
          Update Record
        </b-button>
        <b-button
          variant="outline-dark"
          :disabled="state.busy"
          @click.prevent="cancel()"
        >
          Close Window
        </b-button>
      </template>

    </b-modal>
  </b-container>
</template>

<script>
import QUERY from 'lodash'
import MISC from '@/mixins/misc'
import FORMATTER from '@/mixins/formatter'
import { core } from '@/config/pluginInit'
import { SAdminCategory, SSharedList } from '@/services'

export default {
  name: 'AdminCategories',

  middleware: [
    'auth',
    'admin'
  ],

  mixins: [
    MISC,
    FORMATTER
  ],

  metaInfo: () => ({
    title: 'Categories'
  }),

  data () {
    return {
      state: {
        busy: false,
        editing: false,
        synchronizing: false,
        importing: false
      },
      synchronize: {
        start_date: null,
        end_date: null
      },
      fetching: {
        groups: false
      },
      options: {
        groups: []
      },
      selected: {
        group: null
      },
      category: {
        id: null,
        group: null,
        category_code: null,
        category_name: null,
        active: 1
      },
      tableCategories: {
        busy: false,
        fetching: {
          groups: false
        },
        options: {
          groups: []
        },
        selected: {
          group: null
        },
        filter: {
          search: null,
          group: null
        },
        filterOn: [],
        stacked: this.isMobile(),
        sortBy: null,
        sortDesc: false,
        sortDirection: 'asc',
        pageOptions: [10, 25, 50, 100],
        currentPage: 1,
        totalRows: 0,
        perPage: 10,
        fields: [
          { mobile: 0, key: 'index', label: '#', class: 'text-center' },
          { mobile: 8, key: 'action', class: 'text-center' },
          { mobile: 1, key: 'created_at', class: 'min-w-150px', formatter: this.dateTimeShortFormatter, sortable: true },
          { mobile: 2, key: 'category_code', class: 'min-w-175px' },
          { mobile: 3, key: 'category_name', class: 'min-w-175px' },
          { mobile: 4, key: 'group.group_name', label: 'Group', class: 'min-w-175px' },
          { mobile: 5, key: 'active', class: 'text-center', formatter: this.yesOrNo },
          { mobile: 6, key: 'valid', class: 'text-center' },
          { mobile: 7, key: 'updated_at', class: 'min-w-150px', formatter: this.dateTimeShortFormatter, sortable: true }
        ].sort(this.scaleOnMobile)
      }
    }
  },

  watch: {
    'selected.group' (group) {
      this.category.group = group?.id || null
    },

    'tableCategories.selected.group' (group) {
      this.tableCategories.filter.group = group?.id || null
    }
  },

  mounted () {
    core.index()
    this.getFilterGroups()
  },

  methods: {

    async tableProvider ({ currentPage, perPage, sortBy, sortDesc, filter }) {
      this.tableCategories.busy = true
      return await SAdminCategory.get({
        page: currentPage,
        per_page: perPage,
        sort: sortBy,
        sort_desc: sortDesc,
        filter_text: filter.search,
        filter_group: filter.group
      }).then(({ data }) => {
        this.tableCategories.totalRows = data.total_rows
        return data.items
      }).catch(() => {
        this.tableCategories.totalRows = 0
        return []
      }).finally(() => {
        this.tableCategories.busy = false
      })
    },

    async synchronizeCategories () {
      await this.$refs.formSynchronizer.validate().then(
        validSynchronizer => {
          if (!validSynchronizer) {
            return this.swalInvalid()
          }

          this.state.synchronizing = true
          return SAdminCategory.sync(this.synchronize).then(({ data: { message } }) => {
            this.swalSuccess(message).then(() => {
              this.synchronize.start_date = null
              this.synchronize.end_date = null
              this.$refs.formSynchronizer.reset()
              this.$refs.tableCategories.refresh()
            })
          }).finally(() => {
            this.state.synchronizing = false
          })
        }
      )
    },

    importCategories () {
      this.state.importing = true
      return SAdminCategory.import().then(({ data: { message } }) => {
        this.swalSuccess(message).then(() => {
          this.$refs.tableCategories.refresh()
        })
      }).finally(() => {
        this.state.importing = false
      })
    },

    async getFilterGroups () {
      this.tableCategories.fetching.groups = true
      return await SSharedList.getGroups().then(({ data }) => {
        this.tableCategories.options.groups = data
      }).catch(() => {
        this.tableCategories.options.groups = []
      }).finally(() => {
        this.tableCategories.fetching.groups = false
      })
    },

    async getGroups () {
      this.fetching.groups = true
      return await SSharedList.getGroups().then(({ data }) => {
        this.options.groups = data
      }).catch(() => {
        this.options.groups = []
      }).finally(() => {
        this.fetching.groups = false
      })
    },

    editCategory (category) {
      this.state.editing = true
      this.category.id = category.id
      this.category.category_code = category.category_code
      this.category.category_name = category.category_name
      this.category.active = category.active

      this.getGroups().finally(() => {
        this.selected.group = QUERY.find(this.options.groups, {
          id: Number(category.group_id)
        })
      })

      this.$bvModal.show('modalCategory')
    },

    async validateCategory () {
      await this.$refs.formCategory.validate().then(
        async validCategory => {
          if (!validCategory) {
            return this.swalInvalid()
          }

          this.swalConfirm({
            html: '<h6>Save your changes on this Category?</h6>',
            preConfirm: () => this.updateCategory()
          })
        }
      )
    },

    async updateCategory () {
      this.state.busy = true
      return await SAdminCategory.put(this.category).then(
        ({ data: { message, category } }) => {
          this.$bvModal.hide('modalCategory')
          this.swalSuccess(message).then(() => {
            const tableCategoriesRow = QUERY.find(this.$refs.tableCategories.localItems, {
              id: Number(category.id)
            })
            if (tableCategoriesRow) {
              tableCategoriesRow.group = category.group
              tableCategoriesRow.group_id = category.group_id
              tableCategoriesRow.category_code = category.category_code
              tableCategoriesRow.category_name = category.category_name
              tableCategoriesRow.active = category.active
              tableCategoriesRow.valid = category.valid
              tableCategoriesRow.updated_at = category.updated_at
            }
          })
        }
      ).catch(({ message }) => {
        this.$refs.formCategory.setErrors(message)
      }).finally(() => {
        this.state.busy = false
      })
    }
  }
}
</script>
